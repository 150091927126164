import styled, { css } from "styled-components";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.alignItemsCenter &&
    css`
      align-items: center;
    `}
`;

export default FlexColumn;
