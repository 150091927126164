import React, { useState } from "react";
import { TextField, IconButton } from "@material-ui/core";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { FlexRow, VerticalSpacer } from ".";

const StyledIconButton = styled(IconButton)`
  && {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
    padding: 0.5rem;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    width: 3rem;
    div {
      &::before {
        display: none !important;
      }
      &::after {
        border: none !important;
      }
    }
    input {
      text-align: center;
    }
  }
`;

const QtyInput = () => {
  const [value, setValue] = useState(1);

  const handleAdd = () => {
    setValue(value + 1);
  };

  const handleRemove = () => {
    if (value - 1 <= 0) return;
    setValue(value - 1);
  };

  const handleChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = 1;
    }
    setValue(value);
  };

  return (
    <FlexRow alignItemsCenter>
      <div>
        <StyledIconButton onClick={handleRemove}>
          <RemoveIcon />
        </StyledIconButton>
      </div>
      <VerticalSpacer small />
      <div>
        <StyledTextField value={value} onChange={handleChange} />
      </div>
      <VerticalSpacer small />
      <div>
        <StyledIconButton onClick={handleAdd}>
          <AddIcon />
        </StyledIconButton>
      </div>
    </FlexRow>
  );
};

export default QtyInput;
