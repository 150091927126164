import styled, { css } from "styled-components";

const VerticalSpacer = styled.div`
  width: 1.5rem;
  ${(props) =>
    props.large &&
    css`
      width: 2rem;
    `}
    ${(props) =>
      props.small &&
      css`
        width: 0.5rem;
      `}
  ${(props) =>
    props.extraSmall &&
    css`
      width: 0.3rem;
    `}
`;

export default VerticalSpacer;
