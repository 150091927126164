import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";

import { tokenRefresh } from "./token";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const getAccessToken = () => {
  return localStorage.getItem("authToken");
};

const authTokensLink = setContext(async (_, { headers }) => {
  let accessToken = getAccessToken();
  const result = await tokenRefresh(accessToken);
  accessToken = result;
  const user = localStorage.getItem("user");
  return {
    headers: {
      ...headers,
      appId: JSON.parse(user).appId,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const ip = process.env.REACT_APP_API_URL;

const httpLink = createHttpLink({
  uri: `${ip}graphql`,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authTokensLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});

export default client;
