import axios from "axios";
import qs from "qs";

import { setTokenAndUser } from "../utils/token";

const login = async (credentials) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}back-office/sign-in`,
      qs.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    localStorage.setItem("refreshToken", result.data.refreshToken);
    const decodedUser = setTokenAndUser(result.data.authToken);
    return decodedUser;
  } catch (err) {
    throw err;
  }
};

const refreshToken = async () => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}back-office/token`,
      null,
      {
        headers: {
          "refresh-token": localStorage.getItem("refreshToken"),
        },
      }
    );
    return result.data.authToken;
  } catch (err) {
    console.log(err, "err");
  }
};

const logout = () => {
  try {
    localStorage.clear();
  } catch (err) {
    throw err;
  }
};

export { login, logout, refreshToken };
