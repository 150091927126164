import React from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import Application from "../pages/application";
import Categories from "../pages/categories";
import SaveCategory from "../pages/categories/save"
import Projects from "../pages/projects";
import Contacts from "../pages/contacts";
import Settings from "../pages/settings";
import NotFound from "../pages/404";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import PublicRoute from "./publicRoute";
import PrivateRoute from "./privateRoute";
import { Header } from "../components";
import SaveProduct from "../pages/projects/save";
import ProjectCategories from "../pages/projects/categories"

const PageRoutes = () => {
  const authentication = useSelector((state) => state.authentication);

  return (
    <>
      <Header isLoggedIn={authentication?.isLoggedIn} />
      <Switch>
        {authentication?.isLoggedIn ? <PublicRoute path="/" exact={true} component={Application} /> : <PublicRoute path="/" exact={true} component={Login} />}
        {/* <PublicRoute path="/" exact={true} component={Home} /> */}
        <PublicRoute path="/signup" exact={true} component={Signup} />
        <PrivateRoute
          path="/application"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={Application}
        />
        <PrivateRoute
          path="/categories"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={Categories}
        />
        <PrivateRoute
          path="/categories/save"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={SaveCategory}
        />
        <PrivateRoute
          path="/projects"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={ProjectCategories}
        />
        <PrivateRoute
          path="/projects/save"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={SaveProduct}
        />
        <PrivateRoute
          path="/projects/:id"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={Projects}
        />
        <PrivateRoute
          path="/contacts"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={Contacts}
        />
        <PrivateRoute
          path="/settings"
          exact={true}
          isLoggedIn={authentication?.isLoggedIn}
          component={Settings}
        />
        <PrivateRoute path="/404" component={NotFound} />
        <PublicRoute component={NotFound} />
      </Switch>
    </>
  );
};

export default PageRoutes;
