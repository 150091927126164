import { userConstants } from "../constants";

let user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? { isLoggedIn: true, user } : {};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        isLoggedIn: true,
        loading: false,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return { loading: false, message: action.message };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default authentication;
