import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

const StyledFeatherIcon = styled(FeatherIcon)`
  && {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export default StyledFeatherIcon;
