import React, { useState, useEffect } from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Layout from "../../components/layout";
import { HorizontalSpacer, Paper, FlexRow } from "../../components";
import { trimErrMessage } from "../../functions/";
import { UPDATE_APP, GET_APP } from "../../api/application";

const Application = () => {
  const { appId } = useSelector((state) => state.authentication.user);
  const defautlFormValues = {
    facebookUrl: "",
    emailAddress: "",
    contactNo: "",
    faxNo: "",
    instagramUrl: "",
    youtubeUrl: "",
  };
  const [formValues, setFormValues] = useState(defautlFormValues);
  const { enqueueSnackbar } = useSnackbar();

  const { loading: loadingApp, networkStatus, data } = useQuery(GET_APP, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [updateApp, { loading }] = useMutation(UPDATE_APP, {
    onCompleted: (res) => {
      enqueueSnackbar("Changes saved successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    },
  });

  useEffect(() => {
    if (data) {
      const {
        facebookUrl,
        emailAddress,
        contactNo,
        faxNo,
        instagramUrl,
        youtubeUrl,
      } = data.getApp;
      setFormValues({
        ...formValues,
        facebookUrl,
        emailAddress,
        contactNo,
        faxNo,
        instagramUrl,
        youtubeUrl,
      });
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      const {
        facebookUrl,
        emailAddress,
        contactNo,
        faxNo,
        instagramUrl,
        youtubeUrl,
      } = formValues;
      await updateApp({
        variables: {
          appParams: {
            _id: appId,
            facebookUrl,
            emailAddress,
            contactNo,
            faxNo,
            instagramUrl,
            youtubeUrl,
          },
        },
      });
    } catch (err) {
      enqueueSnackbar(trimErrMessage(err.message), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormValues({ ...formValues, [id]: value });
  };

  return (
    <Layout loading={loading || loadingApp || networkStatus === 4}>
      <Typography variant="h6">Application Info</Typography>
      <HorizontalSpacer />
      <Paper>
        <TextField
          id="emailAddress"
          value={formValues.emailAddress}
          onChange={handleChange}
          label="Email Address"
          fullWidth
          variant="outlined"
          disabled={loadingApp || networkStatus === 4}
        />
        <HorizontalSpacer />
        <TextField
          id="contactNo"
          value={formValues.contactNo}
          onChange={handleChange}
          label="Contact No"
          fullWidth
          variant="outlined"
          disabled={loadingApp || networkStatus === 4}
        />
        <HorizontalSpacer />
        <TextField
          id="faxNo"
          value={formValues.faxNo}
          onChange={handleChange}
          label="Fax No"
          fullWidth
          variant="outlined"
          disabled={loadingApp || networkStatus === 4}
        />
        <HorizontalSpacer />
        <TextField
          id="facebookUrl"
          value={formValues.facebookUrl}
          onChange={handleChange}
          label="Facebook Url"
          fullWidth
          variant="outlined"
          disabled={loadingApp || networkStatus === 4}
        />
        <HorizontalSpacer />
        <TextField
          id="instagramUrl"
          value={formValues.instagramUrl}
          onChange={handleChange}
          label="Instagram Url"
          fullWidth
          variant="outlined"
          disabled={loadingApp || networkStatus === 4}
        />
        <HorizontalSpacer />
        <TextField
          id="youtubeUrl"
          value={formValues.youtubeUrl}
          onChange={handleChange}
          label="Youtube Url"
          fullWidth
          variant="outlined"
          disabled={loadingApp || networkStatus === 4}
        />
      </Paper>
      <HorizontalSpacer />
      <FlexRow>
        <div style={{ marginLeft: "auto" }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading || loadingApp || networkStatus === 4}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </FlexRow>
    </Layout>
  );
};

export default Application;
