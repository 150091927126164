import React, { useState } from "react";
import Layout from "../../components/layout"
const Contacts = () => {

  return (
    <Layout>
      Contacts
    </Layout>
  );
};

export default Contacts;
