import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const alertDialog = ({ title, open, handleClose, handleSubmit, children, loading, isConfirmation, ctaLabel }) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={isConfirmation ? "sm" : "md"}
            fullWidth
            disableEscapeKeyDown={loading}
            disableBackdropClick={loading}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" autoFocus disabled={loading}>
                    {ctaLabel ? ctaLabel : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default alertDialog
