import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core"
import "typeface-roboto";
import { appMaxWidth } from "../utils/constants"

const Main = styled.div`
  /* margin: auto; */
  padding: 1rem;
  /* max-width: ${appMaxWidth}; */
`;

const Layout = (props) => {
  const { children, style, loading } = props;
  return (
    <>
      {loading && <LinearProgress />}
      <Main style={{ ...style }}>{children}</Main>
    </>
  );
};

Layout.defaultProps = {};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
