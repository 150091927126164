export const formatPrice = (price) => {
  if (!price) return;
  let newPrice = price.toFixed(2);
  return newPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isError = (data) => {
  if (data) {
    return true;
  }
  return false;
};

export const trimErrMessage = (err) => {
  return err.replace("GraphQL error:", "").trim();
};
