import gql from "graphql-tag";

export const GET_CATEGORIES = gql`
    query {
        getCategories {
            _id
            name
            description
            categoryImageUrl
            categoryLevel
            order
            subCategories {
                _id
                name
                description
                categoryImageUrl
                categoryLevel
                subCategories {
                    _id
                    name
                    description
                    categoryImageUrl
                    categoryLevel
                    path 
                }
                path
            }
            path
        }
    }
`

export const GET_CATEGORY = gql`
    query($categoryId: ID!) {
        getCategory(categoryId: $categoryId) {
            _id
            name
            description
            categoryImageUrl
            categoryLevel
            order
        }
    }
`

export const CREATE_CATEGORY = gql`
    mutation createCategory($categoryParams: CategoryAddInput) {
        createCategory(categoryParams: $categoryParams) {
            success,
            message
        }
    }
`

export const UPDATE_CATEGORY = gql`
    mutation updateCategory($categoryParams: CategoryEditInput) {
        updateCategory(categoryParams: $categoryParams) {
            success,
            message
        }
    }
`

export const DELETE_CATEGORY = gql`
    mutation deleteCategory($categoryId: ID!) {
        deleteCategory(categoryId: $categoryId) {
            success,
            message
        }
    }
`