import styled from "styled-components";
import { Paper } from "@material-ui/core"

const StyledPaper = styled(Paper)`
  && {
      padding: 1rem;
  }
`;

export default StyledPaper;
