import FeaturedPhoto from "../images/main.png";
import Banner from "../images/Banner.png";
import Banner2 from "../images/Banner-2.png";
import Banner3 from "../images/Banner-3.png";

export const appSettings = {
  name: "Business Name",
  facebookUrl: "https://www.facebook.com/everyshopph",
  contactNo: "831-1416",
  palette: {
    primary: {
      main: "#FFA931",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#B9AC92",
      contrastText: "#ffffff",
    },
  },
};

export const banners = [Banner, Banner2, Banner3];

export const categories = [
  {
    _id: "1",
    name: "Pork",
  },
  {
    _id: "2",
    name: "Beef",
  },
  {
    _id: "3",
    name: "Vegetables",
  },
];

export const product = {
  _id: "1",
  title: "Pepperoni",
  category: {
    _id: "1",
    name: "Pork",
  },
  description:
    "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents.",
  price: 198,
  isBestseller: true,
  imgs: [
    {
      src: FeaturedPhoto,
    },
    {
      src:
        "https://www.sugarsaltmagic.com/wp-content/uploads/2020/03/Stuffed-Crust-Pepperoni-Pizza-1-500x500.jpg",
    },
    {
      src:
        "https://www.wilton.com/dw/image/v2/AAWA_PRD/on/demandware.static/-/Sites-wilton-project-master/default/dwbf4bf597/images/project/WLRECIP-440/2105-6804_DePePiHa_44206-1.jpg?sw=800&sh=800",
    },
    {
      src:
        "https://image.freepik.com/free-photo/pepperoni-pizza-with-mushrooms-table_140725-997.jpg",
    },
    {
      src:
        "https://www.tasteofhome.com/wp-content/uploads/2018/01/Homemade-Pizza_EXPS_HCA20_376_E07_09_2b.jpg",
    },
    {
      src:
        "https://www.tasteofhome.com/wp-content/uploads/2018/01/Pizza-from-Scratch_EXPS_FT20_8621_F_0505_1_home.jpg",
    },
    {
      src:
        "https://www.carveyourcraving.com/wp-content/uploads/2020/04/wholw-wheat-pizza-base-recipe.jpg",
    },
  ],
};
