import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        position: "fixed",
        bottom: "1.5rem",
        right: "1.5rem"
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const FabBtn = ({ handleSubmit }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Fab color="primary" aria-label="add" variant="extended" onClick={handleSubmit}>
                <AddIcon className={classes.extendedIcon} />
                Add
            </Fab>
        </div>
    );
}

export default FabBtn
