import gql from "graphql-tag";

export const UPDATE_APP = gql`
  mutation updateApp($appParams: AppEditInput) {
    updateApp(appParams: $appParams) {
      success
      message
    }
  }
`;

export const GET_APP = gql`
  query {
    getApp {
      _id
      name
      contactNo
      emailAddress
      instagramUrl
      faxNo
      youtubeUrl
      facebookUrl
      phoneNo
    }
  }
`;
