import gql from "graphql-tag";

export const GET_PRODUCTS = gql`
  query getProducts($page: Int!, $perPage: Int!, $filters: FilterProducts) {
    getProducts(page: $page, perPage: $perPage, filters: $filters) {
      items {
        _id
        order
        productName
        productDescription
        images
        relatedProducts {
            _id
            productName
            productDescription
            images
        }
        categories {
          _id
          name
        }
      }
      count
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
        pageCount
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($_id: String) {
    getProduct(_id: $_id) {
      _id
      productName
      productDescription
      categories {
        _id
        name
      }
      images
      isFeatured
      order
    }
  }
`;

export const DELETE_PRODUCT = gql`
    mutation deleteProduct($productId: ID!) {
        deleteProduct(productId: $productId) {
            success,
            message
        }
    }
`

export const CREATE_PRODUCT = gql`
    mutation createProduct($productParams: ProductAddInput) {
        createProduct(productParams: $productParams) {
            success,
            message
        }
    }
`

export const UPDATE_PRODUCT = gql`
    mutation updateProduct($productParams: ProductEditInput) {
        updateProduct(productParams: $productParams) {
            success,
            message
        }
    }
`
