import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";

import Layout from "../../../components/layout";
import { useForm } from "../../../hooks";
import { userActions } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading: loggingIn, message } = useSelector((state) => state.authentication);

  const { values, handleChange, handleSubmit } = useForm(
    (credentials) => signIn(credentials),
    {
      username: "",
      password: "",
    }
  );

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant: "error" });
    }
  }, [message, enqueueSnackbar]);

  const signIn = (credentials) => {
    dispatch(userActions.signIn(credentials, history));
  };

  return (
    <Layout loading={loggingIn}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={handleChange}
              value={values.username}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              value={values.password}
            />
            <Button
              type="submit"
              disabled={loggingIn}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loggingIn ? "Singin in..." : "Sign in"}
            </Button>
          </form>
        </div>
      </Container>
    </Layout>
  );
};

export default Login;
