import React, { useState } from "react";
import Layout from "../../components/layout"
const Settings = () => {

  return (
    <Layout>
      Settings
    </Layout>
  );
};

export default Settings;
