import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  // palette: appSettings.palette,
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiBadge: {
      badge: {
        backgroundColor: "#ff3232",
        color: "#ffffff",
      },
    },
    MuiButton: {
      root: {
        // textTransform: "inherit",
        // "&$disabled": {
        //   backgroundColor: "rgba(0, 0, 0, 0.2) !important",
        //   color: "rgba(0, 0, 0, 0.5) !important",
        // },
      },
    },
  },
});

export default theme;
