import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  Button
} from "@material-ui/core/";
import styled from "styled-components";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { HorizontalSpacer } from "../";
import PackageDialog from "./packageDialog";

const useStyles = makeStyles(theme => ({
  root: {
    background: "transparent"
  },
  media: {
    height: 160,
    position: "relative"
  },
  avatarSmall: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  title: {
    fontWeight: "500"
  }
}));

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const PackageCard = props => {
  const [openDialog, setOpenDialog] = useState(false);
  const { name, details } = props.data;
  const {
    inSettings,
    handleDeleteAction,
    handleEditAction
  } = props;
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Card className={classes.root} elevation={0} square>
        <CardActionArea onClick={() => setOpenDialog(true)}>
          <CardContent>
            <Typography
              variant="body1"
              component="h6"
              className={classes.title}
            >
              <Text>{name}</Text>
            </Typography>
            <HorizontalSpacer small />
            <Typography variant="body2" color="textSecondary" component="p">
              <Text twoLine>{details}</Text>
            </Typography>
          </CardContent>
        </CardActionArea>
        {inSettings && (
          <CardActions>
            <Button startIcon={<EditIcon />} onClick={handleEditAction}>
              Edit
            </Button>
            <Button startIcon={<DeleteIcon />} onClick={handleDeleteAction}>
              Delete
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default PackageCard;
