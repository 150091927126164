import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography
} from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";
import { formatPrice } from "../../functions";
import HorizontalSpacer from "../horizontalSpacer";

const Price = styled.div`
  background: #fff;
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 20px;
  border-radius: 2px;
`;

const Text = styled.span`
  white-space: pre-line;
`;

const CoverPhoto = styled.div`
  height: 200px;
  width: 100%;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PackageDialog = props => {
  const {
    open,
    handleClose,
    inPortfolio,
    inSettings,
    handleDeleteAction,
    handleEditAction
  } = props;
  const { name, thumbnail, details, price, services, user } = props.data;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >

    </Dialog>
  );
};

export default PackageDialog;
