import React from "react"
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core"
import { useHistory } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import CategoryIcon from '@material-ui/icons/Category';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AppsIcon from '@material-ui/icons/Apps';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

const SideNav = ({ open, handleClose }) => {
    const classes = useStyles();
    let history = useHistory();

    const handleChangeRoute = (path) => {
        history.push(path)
        handleClose()
    }

    return <>
        <Drawer open={open} onClose={handleClose}>
            <div className={classes.list}>
                <List>
                    {/* <ListItem
                        button
                        selected={history.location.pathname === "/"}
                        onClick={() => handleChangeRoute("/")}
                    >
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} />
                    </ListItem> */}
                    <ListItem
                        button
                        selected={history.location.pathname === "/application"}
                        onClick={() => handleChangeRoute("/application")}
                    >
                        <ListItemIcon>
                            <AppsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Application"} />
                    </ListItem>
                    <ListItem
                        button
                        selected={history.location.pathname === "/categories"}
                        onClick={() => handleChangeRoute("/categories")}
                    >
                        <ListItemIcon>
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Categories"} />
                    </ListItem>
                    <ListItem
                        button
                        selected={history.location.pathname === "/projects"}
                        onClick={() => handleChangeRoute("/projects")}
                    >
                        <ListItemIcon>
                            <ArtTrackIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Projects"} />
                    </ListItem>
                    {/* <ListItem
                        button
                        selected={history.location.pathname === "/contacts"}
                        onClick={() => handleChangeRoute("/contacts")}
                    >
                        <ListItemIcon>
                            <ContactPhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Contacts"} />
                    </ListItem> */}
                </List>
                <Divider />
                <List>
                    {/* <ListItem
                        button
                        selected={history.location.pathname === "/settings"}
                        onClick={() => handleChangeRoute("/settings")}
                    >
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Settings"} />
                    </ListItem> */}
                    <ListItem button>
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    </>
}

export default SideNav