export const API_URL = process.env.REACT_APP_API_URL;

export const systemPalette = {
  gray: {
    light: "#f5f5f5",
    dark: "#F3F3F3",
  },
};

export const appMaxWidth = "1368px";
