import React from "react"
import Dropzone from "react-dropzone-uploader"

const ImageUploader = ({ isMultiple, changeImageURL }) => {

    const getUploadParams = async ({ file }) => {
        const body = new FormData()
        body.append('fileField', file)
        return { url: `${process.env.REACT_APP_API_URL}back-office/upload`, body }
    }

    const handleChangeStatus = ({ meta, xhr }, status) => {
        if (status === 'done') {
            let response = JSON.parse(xhr.response);
            changeImageURL(response.imageUrl)
        }
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
            accept=".jpg, .png"
            multiple={isMultiple}
            inputContent={`Drag Image${isMultiple ? "s" : ""} or Click to Browse`}
        />
    )
}

export default ImageUploader