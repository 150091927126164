import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { appMaxWidth } from "../utils/constants";
import { FlexRow } from ".";
import { appSettings } from "../data/dummy";
import VerticalSpacer from "./verticalSpacer";

const Main = styled.div`
  padding: 1.5rem;
  background: ${appSettings.palette.secondary.main};
  color: ${appSettings.palette.secondary.contrastText};
`;

const Body = styled(FlexRow)`
  && {
    width: 100%;
    max-width: ${appMaxWidth};
    margin: auto;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    font-weight: 300;
  }
`;

const Footer = () => {
  return (
    <Main>
      <Body>
        <StyledTypography variant="body1">
          {appSettings.name} {new Date().getFullYear()}.
        </StyledTypography>
        {appSettings.contactNo && (
          <>
            {" "}
            <VerticalSpacer extraSmall />
            <StyledTypography variant="body1">
              Call us at {appSettings.contactNo}.
            </StyledTypography>
          </>
        )}
        {appSettings.facebookUrl && (
          <>
            <VerticalSpacer extraSmall />
            <a
              href={appSettings.facebookUrl}
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              <StyledTypography variant="body1">
                Follow us on Facebook.
              </StyledTypography>
            </a>
          </>
        )}
        <div style={{ marginLeft: "auto" }}>
          <StyledTypography variant="body1">
            Powered by Everyshop PH
          </StyledTypography>
        </div>
      </Body>
    </Main>
  );
};

export default Footer;
