import React, { useState } from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Breadcrumbs, Link } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from "@material-ui/lab/Pagination"
import Skeleton from "@material-ui/lab/Skeleton";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import Layout from "../../components/layout"
import { Paper, HorizontalSpacer, Fab, FlexRow, VerticalSpacer, Dialog } from "../../components"
import { GET_PRODUCTS, DELETE_PRODUCT } from "../../api/products"
import { trimErrMessage } from "../../functions/";

const Projects = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(6)
  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState({})
  const [globalLoading, setGlobalLoading] = useState(false)
  let history = useHistory()
  let { id } = useParams();

  const { loading, data, fetchMore } = useQuery(GET_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      page,
      perPage,
      filters: {
        categories: [id]
      }
    }
  });

  const [deleteCategory, { loading: loadingDelete }] = useMutation(DELETE_PRODUCT, {
    onCompleted: (res) => {
      enqueueSnackbar("Product deleted successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setOpen(false)
      setProduct({})
      setPage(1)
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          page,
          perPage,
          filters: {
            categories: [id]
          }
        }
      }]
  });

  const handleDelete = async () => {
    try {
      await deleteCategory({
        variables: {
          productId: product._id
        },
      });

    } catch (err) {
      enqueueSnackbar(trimErrMessage(err.message), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    return
  }

  const handlePageChange = async (event, newPage) => {
    if (page === newPage) {
      return;
    }
    window.scrollTo(0, 0);
    setPage(newPage);
    setGlobalLoading(true);
    await fetchMore({
      variables: {
        page: newPage,
        perPage,
        filters: {
          categories: [id]
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          feed: [...prev.feed, ...fetchMoreResult.feed]
        });
      }
    });
    setGlobalLoading(false);
  };

  return (
    <>
      <Dialog
        title="Delete Confirmation"
        handleClose={() => setOpen(false)}
        handleSubmit={handleDelete}
        open={open}
        isConfirmation
        loading={loadingDelete}
        ctaLabel="Yes"
      >
        <Typography variant="body1">Are you sure you want to delete {product.productName}?</Typography>
      </Dialog>
      <Fab handleSubmit={() => history.push(id ? `/projects/save?categoryId=${id}` : '/projects/save')} />
      <Layout loading={loading || loadingDelete || globalLoading}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/projects">
            Categories
            </Link>
          <Typography color="textPrimary">Projects</Typography>
        </Breadcrumbs>
        <Typography variant="h6">Projects</Typography>
        <HorizontalSpacer />
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Category
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(globalLoading || loading) ? Array.from(new Array(6)).map((data, index) => (
                  <TableRow key={index.toString()}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="rect" height={40} width="100%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rect" height={40} width="100%" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="rect" height={40} width="100%" />
                    </TableCell>
                  </TableRow>
                ))
                  : data?.getProducts?.items?.map((data, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell component="th" scope="row">{data.order ? `${data.order}.) ` : ''}{data.productName}</TableCell>
                      <TableCell>{data.categories[0].name}</TableCell>
                      <TableCell align="right">
                        <FlexRow>
                          <Button
                            onClick={() => history.push(`/projects/save?id=${data._id}&categoryId=${id}`)}
                            variant="outlined"
                            color="primary"
                            startIcon={<EditIcon />}
                          >
                            Edit
                        </Button>
                          <VerticalSpacer small />
                          <Button
                            onClick={() => {
                              setOpen(true)
                              setProduct(data)
                            }}
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                        </Button>
                        </FlexRow>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && data?.getProducts?.items?.length <= 0 ?
            (
              <>
                <HorizontalSpacer />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ textAlign: "center" }}
                >
                  No Products Found
                </Typography>
              </>
            )
            : ""}
        </Paper>
        <HorizontalSpacer large />
        <FlexRow justifyContentCenter>
          <Pagination
            disabled={data?.getProducts.pageInfo.pageCount <= 1}
            count={data?.getProducts.pageInfo.pageCount}
            page={page}
            color="primary"
            onChange={handlePageChange}
          />
        </FlexRow>
      </Layout>
    </>
  );
};

export default Projects;
