import { userConstants } from "../constants";
import { login, logout } from "../../services";

const signIn = (credentials, history) => {
  const request = () => {
    return { type: userConstants.LOGIN_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.LOGIN_SUCCESS, user };
  };
  const failure = (error) => {
    return {
      type: userConstants.LOGIN_FAILURE,
      message: "Invalid username/password.",
    };
  };

  return async (dispatch) => {
    dispatch(request());
    try {
      const result = await login(credentials);
      dispatch(success(result));
      history.push("/");
    } catch (err) {
      dispatch(failure());
      console.log(err);
    }
  };
};

const signOut = () => {
  logout();
  return { type: userConstants.LOGOUT };
};

export const userActions = {
  signIn,
  signOut,
};
