import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Breadcrumbs,
  Link,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  List,
  Avatar,
  ListItem,
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Layout from "../../components/layout";
import {
  Paper,
  HorizontalSpacer,
  FlexRow,
  ImageUploader,
  VerticalSpacer,
  ImageViewer,
  Dialog,
} from "../../components";
import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT,
} from "../../api/products";
import { GET_CATEGORIES } from "../../api/categories";
import { trimErrMessage } from "../../functions/";
import { GET_COUNT } from "../../api/app";
import { PRODUCT } from "../../static/";

const ImageEditor = styled.div`
  max-height: 16rem;
  overflow: scroll;
`;

const SaveProduct = () => {
  let queryParams = new URLSearchParams(useLocation().search);
  let history = useHistory();
  const productId = queryParams.get("id");
  const defaultFormValues = {
    productName: "",
    productDescription: "",
    categories: [],
    images: [],
    order: "",
  };
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [images, setImages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isFeatured, setIsFeatured] = useState(false);
  let location = useLocation();
  let { categoryId } = queryString.parse(location.search);
  const [originalOrder, setOriginalOrder] = useState(1);
  const [count, setCount] = useState(1);

  const handleOpenGallery = (index) => {
    setCurrentImg(index);
    setOpen(true);
  };

  useEffect(() => {
    if (categoryId) {
      setFormValues({
        ...formValues,
        categories: [...formValues.categories, categoryId],
      });
    }
  }, []);

  const { loading: loadingCount } = useQuery(GET_COUNT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      type: PRODUCT,
      categories: [categoryId],
    },
    onCompleted: (res) => {
      if (res?.getCount) {
        let tpmCount = res?.getCount;
        if (!productId) {
          tpmCount += 1;
        }
        setCount(tpmCount);
      }
      if (!productId) {
        setFormValues({
          ...formValues,
          order: res?.getCount + 1,
        });
      }
    },
  });

  const { loading: loadingCategories, data: dataCategories } = useQuery(
    GET_CATEGORIES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const { loading: loadingApp } = useQuery(GET_PRODUCT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      _id: productId,
    },
    skip: queryParams.get("id") ? false : true,
    onCompleted: (res) => {
      const { productName, categories, order } = res?.getProduct;
      const newCategories = categories.map((data) => data._id);
      setFormValues({
        ...formValues,
        productName,
        categories: newCategories,
        order,
      });
      if (res?.getProduct?.productDescription) {
        const blocksFromHtml = htmlToDraft(res?.getProduct?.productDescription);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
      setImages(res?.getProduct?.images);
      setIsFeatured(res?.getProduct.isFeatured);
      setOriginalOrder(res?.getProduct?.order);
    },
    onError: () => {
      enqueueSnackbar("Product not found", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      history.push("/projects");
    },
  });

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    onCompleted: (res) => {
      enqueueSnackbar("Project added successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setFormValues(defaultFormValues);
      history.push(`/projects/${categoryId}`);
    },
  });

  const [updateProduct, { loading: loadingUpdate }] = useMutation(
    UPDATE_PRODUCT,
    {
      onCompleted: (res) => {
        enqueueSnackbar("Project saved successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        setFormValues(defaultFormValues);
        history.push(`/projects/${categoryId}`);
      },
    }
  );

  const handleImagesChange = (data) => {
    setImages([...images, data]);
  };

  const handleSubmit = async () => {
    const { productName, categories, order } = formValues;
    const productDescription = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    try {
      if (productId) {
        await updateProduct({
          variables: {
            productParams: {
              _id: productId,
              categories,
              productName,
              images,
              productDescription,
              isFeatured,
              order: parseInt(order),
              modifiedOrder: originalOrder !== formValues.order,
            },
          },
        });
      } else {
        await createProduct({
          variables: {
            productParams: {
              categories,
              productName,
              images,
              productDescription,
              isFeatured,
              order: parseInt(order),
              modifiedOrder: parseInt(formValues.order) !== count,
            },
          },
        });
      }
    } catch (err) {
      enqueueSnackbar(trimErrMessage(err.message), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    return;
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormValues({ ...formValues, [id]: value });
  };

  let isLoading =
    loadingCount || loading || loadingUpdate || loadingApp || loadingCategories;

  return (
    <Layout loading={isLoading}>
      <Dialog
        title="Delete Confirmation"
        handleClose={() => setOpenConfirmation(false)}
        handleSubmit={() => {
          let newImages = images;
          newImages.splice(selectedIndex, 1);
          setImages([...newImages]);
          setOpenConfirmation(false);
        }}
        open={openConfirmation}
        isConfirmation
        ctaLabel="Yes"
      >
        <Typography variant="body1">
          Are you sure you want to delete this image?
        </Typography>
      </Dialog>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/projects">
          Categories
        </Link>
        {categoryId && (
          <Link color="inherit" href={`/projects/${categoryId}`}>
            Projects
          </Link>
        )}
        <Typography color="textPrimary">
          {productId ? "Edit" : "Add"} Project
        </Typography>
      </Breadcrumbs>
      <Typography variant="h6">{productId ? "Edit" : "Add"} Project</Typography>
      <HorizontalSpacer />
      <Paper>
        {images.length > 0 && (
          <>
            <ImageViewer
              images={images.map((data) => ({ src: data }))}
              open={open}
              handleClose={() => {
                setOpen(false);
                setCurrentImg(0);
              }}
              currentImg={currentImg}
              setCurrentImg={setCurrentImg}
            />
            <Typography variant="body1">Uploaded Images</Typography>
            <HorizontalSpacer />
            <ImageEditor>
              <List>
                {images.map((data, index) => (
                  <ListItem key={index.toString()}>
                    <ListItemAvatar onClick={() => handleOpenGallery(index)}>
                      <Avatar
                        src={data}
                        style={{ height: "60px", width: "60px" }}
                      />
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          setOpenConfirmation(true);
                          setSelectedIndex(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </ImageEditor>
            <HorizontalSpacer />
          </>
        )}
        <ImageUploader isMultiple changeImageURL={handleImagesChange} />
        <HorizontalSpacer />
        <TextField
          id="productName"
          value={formValues.productName}
          onChange={handleChange}
          label="Name"
          fullWidth
          variant="outlined"
          disabled={isLoading}
        />
        <HorizontalSpacer />
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
          <Select
            labelId="categories-label"
            id="categories-select"
            value={
              formValues.categories?.length > 0 ? formValues.categories[0] : ""
            }
            onChange={(e) => {
              const newCategories = [];
              newCategories.push(e.target.value);
              setFormValues({
                ...formValues,
                categories: newCategories,
              });
            }}
            disabled={!!categoryId || isLoading}
          >
            {dataCategories?.getCategories?.map((data, index) => {
              return (
                <MenuItem value={data._id} key={index.toString()}>
                  {data.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <HorizontalSpacer />
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-simple-select-label">Select Order</InputLabel>
          <Select
            labelId="categories-label"
            id="categories-select"
            disabled={isLoading}
            value={formValues.order}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                order: e.target.value,
              });
            }}
          >
            {Array.from(new Array(count)).map((data, index) => {
              return (
                <MenuItem value={index + 1} key={index.toString()}>
                  {index + 1}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <HorizontalSpacer />
        <Editor
          editorState={editorState}
          editorStyle={{
            border: "1px solid rgba(0,0,0,0.12)",
            minHeight: "12rem",
            padding: "1rem",
          }}
          onEditorStateChange={(editorState) => setEditorState(editorState)}
        />
        <HorizontalSpacer />
        <FormControlLabel
          control={
            <Switch
              checked={isFeatured}
              onChange={(e) => setIsFeatured(e.target.checked)}
              name="isFeatured"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          }
          label="Featured"
        />
      </Paper>
      <HorizontalSpacer />
      <FlexRow>
        <div style={{ marginLeft: "auto" }}>
          <FlexRow>
            <Button
              onClick={() => history.push(`/projects/${categoryId}`)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <VerticalSpacer small />
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </FlexRow>
        </div>
      </FlexRow>
    </Layout>
  );
};

export default SaveProduct;
