import React, { useState } from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit';
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Layout from "../../components/layout"
import { Paper, HorizontalSpacer, Fab, FlexRow, Dialog } from "../../components"
import { GET_CATEGORIES, DELETE_CATEGORY } from "../../api/categories"
import { trimErrMessage } from "../../functions/";

const Categories = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false)
    const [category, setCategory] = useState({})
    let history = useHistory()

    const { loading, data } = useQuery(GET_CATEGORIES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const [deleteCategory, { loading: loadingDelete }] = useMutation(DELETE_CATEGORY, {
        onCompleted: (res) => {
            enqueueSnackbar("Category deleted successfully", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            setOpen(false)
            setCategory({})
        },
        refetchQueries: [{ query: GET_CATEGORIES }]
    });

    const handleDelete = async () => {
        try {
            await deleteCategory({
                variables: {
                    categoryId: category._id
                },
            });

        } catch (err) {
            enqueueSnackbar(trimErrMessage(err.message), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
        }
        return
    }

    return (
        <>
            <Dialog
                title="Delete Confirmation"
                handleClose={() => setOpen(false)}
                handleSubmit={handleDelete}
                open={open}
                isConfirmation
                loading={loadingDelete}
                ctaLabel="Yes"
            >
                <Typography variant="body1">Are you sure you want to delete {category.name}?</Typography>
            </Dialog>
            <Layout loading={loading || loadingDelete}>
                <Typography variant="h6">Categories</Typography>
                <HorizontalSpacer />
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.getCategories?.map((data, index) => (
                                    <TableRow key={index.toString()}>
                                        <TableCell component="th" scope="row">{data.name}</TableCell>
                                        <TableCell align="right">
                                            <FlexRow>
                                                <Button
                                                    onClick={() => history.push(`/projects/${data._id}`)}
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<EditIcon />}
                                                >
                                                    Manage Projects
                                                </Button>
                                            </FlexRow>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loading ? (<>
                        <HorizontalSpacer />
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            style={{ textAlign: "center" }}
                        >
                            Loading Categories...
                </Typography>

                    </>) : data?.getCategories?.length <= 0 ?
                            (
                                <>
                                    <HorizontalSpacer />
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        style={{ textAlign: "center" }}
                                    >
                                        No Categories Found
                </Typography>
                                </>
                            )
                            : ""}
                </Paper>
            </Layout>
        </>
    );
};

export default Categories;
