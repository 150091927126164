import jwtDecode from "jwt-decode";
import { refreshToken } from "../services";

const checkIfTokenExpired = (token) => {
  const { exp } = jwtDecode(token);
  const expirationTime = exp * 1000 - 60000;

  return Date.now() >= expirationTime ? true : false;
};

const tokenRefresh = async (token) => {
  if (checkIfTokenExpired(token)) {
    const result = await refreshToken();
    setTokenAndUser(result);
    return result;
  }
  return token;
};

const setTokenAndUser = (token) => {
  const decodeUser = JSON.stringify(jwtDecode(token));
  localStorage.setItem("authToken", token);
  localStorage.setItem("user", decodeUser);
  return decodeUser;
};

export { setTokenAndUser, tokenRefresh };
