import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, TextField, Button, Breadcrumbs, Link, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"
import { useSnackbar } from "notistack";
import Layout from "../../components/layout"
import { Paper, HorizontalSpacer, FlexRow, ImageUploader, VerticalSpacer, ImageGallery } from "../../components"
import { CREATE_CATEGORY, GET_CATEGORY, UPDATE_CATEGORY } from "../../api/categories"
import { trimErrMessage } from "../../functions/";
import { GET_COUNT } from "../../api/app"
import { CATEGORY } from "../../static/"

const SaveCategory = () => {
    let queryParams = new URLSearchParams(useLocation().search)
    let history = useHistory()
    const categoryId = queryParams.get("id")
    const defaultFormValues = {
        name: "",
        order: ""
    };
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [categoryImageUrl, setCategoryImageUrl] = useState("")
    const { enqueueSnackbar } = useSnackbar();
    const [count, setCount] = useState(1)
    const [originalOrder, setOriginalOrder] = useState(1)

    const [createCategory, { loading }] = useMutation(CREATE_CATEGORY, {
        onCompleted: (res) => {
            enqueueSnackbar("Category added successfully", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            setFormValues(defaultFormValues)
            history.push("/categories")
        }
    });

    const { loading: loadingCount, data: dataCount } = useQuery(GET_COUNT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        variables: {
            type: CATEGORY
        },
        onCompleted: res => {
            if (res?.getCount) {
                let tpmCount = res?.getCount
                if (!categoryId) {
                    tpmCount += 1
                }
                setCount(tpmCount)
            }
            if (!categoryId) {
                setFormValues({
                    ...formValues,
                    order: res?.getCount + 1
                })
            }
        }
    });

    const [updateCategory, { loading: loadingUpdate }] = useMutation(UPDATE_CATEGORY, {
        onCompleted: (res) => {
            enqueueSnackbar("Category saved successfully", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            setFormValues(defaultFormValues)
            history.push("/categories")
        }
    });

    const { loading: loadingApp, data } = useQuery(GET_CATEGORY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        variables: {
            categoryId
        },
        skip: queryParams.get("id") ? false : true,
        onCompleted: res => {
            setFormValues({
                ...res?.getCategory
            })
            setCategoryImageUrl(res?.getCategory?.categoryImageUrl)
            setOriginalOrder(res?.getCategory?.order)
        },
        onError: () => {
            enqueueSnackbar("Category not found", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            history.push("/categories")
        }
    });

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormValues({ ...formValues, [id]: value });
    };

    const handleImageChange = (data) => {
        setCategoryImageUrl(data)
    }

    const handleSubmit = async () => {
        const { name, order } = formValues
        try {
            if (categoryId) {
                await updateCategory({
                    variables: {
                        categoryParams: {
                            name,
                            categoryImageUrl,
                            _id: categoryId,
                            order: parseInt(order),
                            modifiedOrder: originalOrder !== formValues.order
                        },
                    },
                });
            }
            else {
                await createCategory({
                    variables: {
                        categoryParams: {
                            name,
                            categoryImageUrl,
                            categoryLevel: 1,
                            order: parseInt(order),
                            modifiedOrder: parseInt(formValues.order) !== count
                        },
                    },
                });
            }

        } catch (err) {
            enqueueSnackbar(trimErrMessage(err.message), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
        }
        return
    }

    let isLoading = loading || loadingApp || loadingUpdate || loadingCount

    return <Layout loading={isLoading}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/categories">
                Categories
            </Link>
            <Typography color="textPrimary">{categoryId ? "Edit" : "Add"} Category</Typography>
        </Breadcrumbs>
        <Typography variant="h6">{categoryId ? "Edit" : "Add"} Category</Typography>
        <HorizontalSpacer />
        <Paper>
            <ImageUploader imageURL={categoryImageUrl} changeImageURL={handleImageChange} isMultiple={false} />
            <HorizontalSpacer />
            {categoryImageUrl && !isLoading &&
                <>
                    <FlexRow justifyContentCenter>
                        <ImageGallery images={[categoryImageUrl]} />
                    </FlexRow>
                    <HorizontalSpacer />
                </>
            }
            <TextField
                id="name"
                value={formValues.name}
                onChange={handleChange}
                label="Name"
                fullWidth
                variant="outlined"
                disabled={isLoading}
            />
            <HorizontalSpacer />
            <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">
                    Select Order
                </InputLabel>
                <Select
                    labelId="categories-label"
                    id="categories-select"
                    disabled={isLoading}
                    value={formValues.order}
                    onChange={e => {
                        setFormValues({
                            ...formValues,
                            order: e.target.value
                        })
                    }}
                >
                    {Array.from(new Array(count)).map((data, index) => {
                        return <MenuItem value={index + 1} key={index.toString()}>
                            {index + 1}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
            <HorizontalSpacer />
        </Paper>
        <HorizontalSpacer />
        <FlexRow>
            <div style={{ marginLeft: "auto" }}>
                <FlexRow>
                    <Button
                        onClick={() => history.push("/categories")}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <VerticalSpacer small />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </FlexRow>
            </div>
        </FlexRow>
    </Layout>
}

export default SaveCategory