import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider } from "react-redux";

import PageRoutes from "./routes/navigation";
import theme from "./utils/muiTheme";
import client from "./utils/apolloConnection";
import store from "./redux/store";
import "./index.css";
import 'react-dropzone-uploader/dist/styles.css'
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <PageRoutes />
            </BrowserRouter>
          </SnackbarProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
